import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { patchState, signalStore, withComputed, withMethods } from '@ngrx/signals';
import { EntityId, removeAllEntities, SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';

import {
  setError,
  setLoaded,
  withLogger,
  withRequestStatus,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS,
  KioskAppointmentsNotFoundDialog,
  KioskAppointmentsNotFoundDialogData,
  KioskWrongLocationDialog,
  KioskWrongLocationDialogData,
  WRONG_LOCATION_DIALOG_TRIGGERS,
} from '@abbadox-monorepo/kiosk-ui';
import { KioskConfigurationsStore } from '@abbadox-monorepo/kiosk-workflows-data-access';

import { parseAppointments } from './appointments.parser';
import { AppointmentGroup } from './models/appointments.model';
import { AppointmentsHttpService } from './services/appointments-http-client.service';

const selectId: SelectEntityId<AppointmentGroup> = (appoitnment) => appoitnment.appointmentId;
const coerceToNumbers = (data: Array<string | number>) => data?.map((x) => Number(x));

export const AppointmentsStore = signalStore(
  { providedIn: 'root' },
  withEntities<AppointmentGroup>(),
  withSelectedEntity(),
  withRequestStatus(),
  withLogger('appointments'),
  withComputed(
    ({ entities, ids }, kioskConfigurationsStore = inject(KioskConfigurationsStore), authStore = inject(AuthStore)) => {
      const locations = computed(() =>
        entities().map(({ locationId, locationName }) => ({ locationId: Number(locationId), locationName })),
      );
      const atCorrectLocation = computed(() =>
        locations().some((a) => Number(a.locationId) === Number(authStore.currentLocation().locationId)),
      );
      const appointmentLocation = computed(() => locations()[0]);
      const appointmentIdsAsNumbers = computed(() => coerceToNumbers(ids()));
      const appointmentIdsAsString = computed(() => ids().join(','));
      const eventPayload = computed(() => ({
        accountName: authStore.accountName(),
        appointmentId: Number(ids()[0]),
        newStatusId: kioskConfigurationsStore.appointmentStatusChangeUponCompletion(),
      }));

      return {
        locations,
        atCorrectLocation,
        appointmentLocation,
        appointmentIdsAsNumbers,
        appointmentIdsAsString,
        eventPayload,
      };
    },
  ),
  withMethods(
    (
      state,
      appointmentsHttpService = inject(AppointmentsHttpService),
      kioskConfigurationsStore = inject(KioskConfigurationsStore),
      authStore = inject(AuthStore),
      dialog = inject(MatDialog),
    ) => {
      function resetAppointentsState(): void {
        patchState(state, removeAllEntities(), { selectedEntityId: null });
      }

      function showWronglocationDialog(currentLocation: string, sessionTimeout: number): void {
        const { appointmentLocation } = state;

        dialog
          .open<KioskWrongLocationDialog, KioskWrongLocationDialogData>(KioskWrongLocationDialog, {
            data: {
              title: "You're at the wrong location",
              scheduledLocation: appointmentLocation().locationName,
              currentLocation,
              maxTimeout: sessionTimeout,
              sessionTimeout,
            },
          })
          .afterClosed()
          .subscribe((event) => {
            if ([WRONG_LOCATION_DIALOG_TRIGGERS.TIMEOUT, WRONG_LOCATION_DIALOG_TRIGGERS.CLOSE_BUTTON].includes(event)) {
              resetAppointentsState();
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      function showWrongAppointmentStatus(sessionTimeout: number): void {
        dialog
          .open<KioskAppointmentsNotFoundDialog, KioskAppointmentsNotFoundDialogData>(KioskAppointmentsNotFoundDialog, {
            data: { title: 'No Appointments Today', maxTimeout: sessionTimeout, sessionTimeout },
          })
          .afterClosed()
          .subscribe((event) => {
            if (event === APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS.TIMEOUT) {
              resetAppointentsState();
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      async function checkPatientAppointments(patientId: EntityId | null): Promise<void> {
        if (!patientId) {
          return;
        }

        try {
          const appointments = await appointmentsHttpService.findAppointmentsAsPromise({
            patientId: String(patientId),
            statusFilters: kioskConfigurationsStore.allowableStatuses(),
          });

          patchState(state, setAllEntities(parseAppointments(appointments), { selectId }), setLoaded);

          if (!state.atCorrectLocation()) {
            showWronglocationDialog(
              authStore.currentLocation().locationName,
              kioskConfigurationsStore.sessionTimeoutInSeconds(),
            );
          }
        } catch (err) {
          const error = err as HttpErrorResponse;
          patchState(state, setError(error.message));
          showWrongAppointmentStatus(kioskConfigurationsStore.sessionTimeoutInSeconds());
        }
      }

      async function createNewAppointmentsEvent(): Promise<void> {
        try {
          await appointmentsHttpService.createNewAppointmentEventAsPromise(state.eventPayload());
        } catch (err) {
          const error = err as HttpErrorResponse;
          patchState(state, setError(error.message));
        }
      }

      return { resetAppointentsState, checkPatientAppointments, createNewAppointmentsEvent };
    },
  ),
);
