import { Component, inject, input, output } from '@angular/core';

import { KIOSK_APP_VERSION } from '@abbadox-monorepo/kiosk-core-http-client';
import { IdsButton, IdsIcon } from '@abbadox-monorepo/shared-ui';

export type FooterNextEvent = { currentRoute: string; nextRoute: string };

@Component({
  selector: 'kiosk-footer',
  standalone: true,
  imports: [IdsButton, IdsIcon],
  template: `
    <footer class="footer-drop-shadow-light fixed bottom-0 left-0 flex w-full max-w-full flex-col">
      @if (nextStep() || prevStep()) {
        <div class="flex h-[84px] items-center justify-between bg-white p-3 px-4">
          <button
            class="h-[3.75rem] !text-[36px]"
            ids-adc-button
            color="tertiary"
            (click)="handlePrevStepClicked(prevStep())"
          >
            <i idsIcon class="text-text-tertiary !h-8 !w-8" icon="arrow-left"></i>
            Back
          </button>

          @if (currentStep()) {
            <button
              class="h-[3.75rem] !text-[36px]"
              ids-adc-button
              ids-outline-button
              color="critical"
              (click)="handleRestartWorkflowClicked()"
            >
              Restart
            </button>
          }

          @if (nextButtonVisible()) {
            <button
              class="h-[3.75rem] !text-[36px]"
              ids-adc-button
              color="primary"
              (click)="handleNextStepClicked(currentStep(), nextStep())"
            >
              <i idsIcon class="text-text-primary-dark !h-8 !w-8" icon="arrow-right" iconPositionEnd></i>
              Next
            </button>
          } @else {
            <!-- keeps the spacing of this button when it's not visible so spacing remains even with other buttons -->
            <div class="w-[155px]"></div>
          }
        </div>
      }

      <div
        class="border-t-border-medium text-text-tertiary flex items-center justify-between border border-solid bg-white p-1 px-4 text-sm"
      >
        <span>Powered by AbbaDox</span>
        <span>Copyright &#169; 2024 AbbaDox. All rights reserved.</span>
        <button (click)="handleLogoutClick()">version: {{ appVersion }}</button>
      </div>
    </footer>
  `,
})
export class KioskFooter {
  readonly appVersion = inject(KIOSK_APP_VERSION);

  readonly currentStep = input('');
  readonly prevStep = input('');
  readonly nextStep = input('');
  readonly nextButtonVisible = input(false);

  readonly onPrevStepClicked = output<string>();
  readonly onRestartWorkflowClicked = output();
  readonly onNextStepClicked = output<FooterNextEvent>();
  readonly onLogoutButtonClicked = output();

  handlePrevStepClicked(route: string) {
    this.onPrevStepClicked.emit(route);
  }

  handleRestartWorkflowClicked() {
    this.onRestartWorkflowClicked.emit();
  }

  handleNextStepClicked(currentRoute: string, nextRoute: string) {
    this.onNextStepClicked.emit({ currentRoute, nextRoute });
  }

  handleLogoutClick() {
    if (!this.currentStep()) {
      this.onLogoutButtonClicked.emit();
    }
  }
}
