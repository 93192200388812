import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

import { DocumentParams } from '../models/upload.models';

@Injectable({ providedIn: 'root' })
export class UploadHttpService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);

  /**
   * Checks patient has id images uploaded.
   *
   * @param query - the patient mrn and worktype to search document
   * @returns boolean if images exist or not
   */
  verifyPatientIdUploaded(query: DocumentParams): Observable<boolean> {
    let params = new HttpParams();

    for (const key in query) {
      if (query[key]) {
        params = params.append(key, query[key]);
      }
    }

    return this.kioskApiHttpService.get<boolean>(`/documents/images/id`, params);
  }

  verifyPatientIdUploadedAsPromise(query: DocumentParams): Promise<boolean> {
    return lastValueFrom(this.verifyPatientIdUploaded(query));
  }

  /**
   * Checks patient has insurance images uploaded.
   *
   * @param query - the patient mrn and worktype to search document
   * @returns boolean if images exist or not
   */
  verifyPatientInsuranceUploaded(query: DocumentParams): Observable<boolean> {
    let params = new HttpParams();

    for (const key in query) {
      if (query[key]) {
        params = params.append(key, query[key]);
      }
    }
    return this.kioskApiHttpService.get<boolean>(`/documents/images/insurance`, params);
  }

  verifyPatientInsuranceUploadedAsPromise(query: DocumentParams): Promise<boolean> {
    return lastValueFrom(this.verifyPatientInsuranceUploaded(query));
  }
}
