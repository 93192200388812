import { inject, Injectable } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS } from '@abbadox-monorepo/core-constants';

import { IDLE_STATE } from '../models/idle.models';

/**
 * Service to control the idling state of the app.
 *
 * Derivative of the following service to use with NgRx.
 * @source https://github.com/moribvndvs/ng2-idle/issues/108
 */
@Injectable({ providedIn: 'root' })
export class IdleService {
  private isSetup = false;

  private readonly idle = inject(Idle);

  /**
   * Checks to see if idle service is currently running
   */
  isServiceRunning(): boolean {
    return this.idle.isRunning();
  }

  /**
   * Starts the idle service
   */
  startIdling(): void {
    if (this.idle.isRunning()) {
      this.idle.stop();
    }

    this.idle.watch();
  }

  /**
   * Stops the idle services
   */
  stopIdling(): void {
    this.idle.stop();
  }

  /**
   * Sets up the idle service's defaults
   */
  setup(idleTimeoutSeconds: number, setIdleState: (idleState?: IDLE_STATE) => void) {
    if (!this.isSetup) {
      this.idle.setIdle(DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS); // how long can they be inactive before considered idle, in seconds
      this.idle.setTimeout(idleTimeoutSeconds); // how long can they be idle before considered timed out, in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      // do something when the user becomes idle
      this.idle.onIdleStart.subscribe(() => {
        setIdleState(IDLE_STATE.IDLE);
      });

      // do something when the user is no longer idle
      this.idle.onIdleEnd.subscribe(() => {
        setIdleState(IDLE_STATE.NOT_IDLE);
      });

      this.idle.onTimeout.subscribe(() => {
        setIdleState(IDLE_STATE.TIMED_OUT);
      });

      this.isSetup = true;
    }
  }
}
