import { computed, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { withLogger } from '@abbadox-monorepo/core-data-access';
import { UploadSecureSignature } from '@abbadox-monorepo/core-utils';
import { AppointmentsStore } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import { PatientsSearchStore } from '@abbadox-monorepo/kiosk-patient-data-access';

import { initialFilesState, UploadFile } from './models/file.models';

export const FilesStore = signalStore(
  { providedIn: 'root' },
  withState(initialFilesState),
  withLogger('files state'),
  withComputed(
    (
      { front, back, secureParams },
      authStore = inject(AuthStore),
      patientsSearchStore = inject(PatientsSearchStore),
      appointmentsStore = inject(AppointmentsStore),
    ) => {
      const generalMetadata = computed(() => ({
        accountName: authStore.accountName() ?? '',
        patientID: String(patientsSearchStore.selectedEntityId() ?? ''),
        appointmentIDs: appointmentsStore.appointmentIdsAsString(),
      }));
      const idImageMetadata = computed(() => ({ ...generalMetadata(), workType: authStore.idsWorktype() }));
      const insuranceImageMetadata = computed(() => ({
        ...generalMetadata(),
        workType: authStore.insuranceWorktype(),
      }));
      const hasFront = computed(() => Boolean(front()?.name));
      const hasBack = computed(() => Boolean(back()?.name));
      const hasImages = computed(() => hasFront() && hasBack());
      const frontPreviewUrl = computed(
        () =>
          `${front()?.cdnUrl}-/preview/-/resize/600x/-/autorotate/no/?token=exp=${secureParams().secureExpire}~hmac=${secureParams().secureSignature}`,
      );
      const backPreviewUrl = computed(
        () =>
          `${back()?.cdnUrl}-/preview/-/resize/600x/-/autorotate/no/?token=exp=${secureParams().secureExpire}~hmac=${secureParams().secureSignature}`,
      );

      return { idImageMetadata, insuranceImageMetadata, hasFront, hasBack, hasImages, frontPreviewUrl, backPreviewUrl };
    },
  ),
  withMethods((state) => ({
    addFrontFile(file: UploadFile): void {
      patchState(state, { front: file });
    },
    addBackFile(file: UploadFile): void {
      patchState(state, { back: file });
    },
    setSecureParams(secureParams: UploadSecureSignature): void {
      patchState(state, { secureParams });
    },
    resetFilesState(): void {
      patchState(state, initialFilesState);
    },
  })),
);
