import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PushPipe } from '@ngrx/component';
import { filter, of, switchMap, tap, timer } from 'rxjs';

import { STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { IdsCardComponent, IdsCardContent, IdsProgressBar } from '@abbadox-monorepo/shared-ui';

import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export const enum APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS {
  CLOSE_ICON = 'Icon',
  TIMEOUT = 'Timeout',
}

export interface KioskAppointmentsNotFoundDialogData extends KioskDialogData {
  maxTimeout: number;
  sessionTimeout: number;
}

@Component({
  selector: 'kiosk-appointments-not-found-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsCardComponent, IdsCardContent, IdsProgressBar],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClick()">
      <ids-card tier-2 ids-card-outlined color="var(--color-scheme-alert)">
        <ids-card-content>
          @if (timer$ | ngrxPush) {}
          <p class="message-line">
            Your appointment needs to be checked-in by a front desk representative. Please see front desk
          </p>
          <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
        </ids-card-content>
      </ids-card>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./appointments-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskAppointmentsNotFoundDialog {
  readonly dialogRef = inject(MatDialogRef);
  readonly data = inject<KioskAppointmentsNotFoundDialogData>(MAT_DIALOG_DATA);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);
  readonly sessionTimeout = model(this.data.sessionTimeout);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = of(this.sessionTimeout()).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClick() {
    this.dialogRef.close(APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS.CLOSE_ICON);
  }

  returnToHome() {
    this.dialogRef.close(APPOINTMENTS_NOT_FOUND_DIALOG_TRIGGERS.TIMEOUT);
  }
}
