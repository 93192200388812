/* eslint-disable @typescript-eslint/no-unused-vars */
import { Appointment, AppointmentGroup } from './models/appointments.model';

let uniqueId = 0;

/**
 * Creates a new appointment with grouped procedures.
 *
 * @param appointment - appointment to transform
 * @param uniqueGroup - whether to use a guid for a unique group
 * @returns - a new appointment with procedure group
 */
const createNewGroup = (appointment: Appointment, uniqueGroup = false): AppointmentGroup => {
  const { appointmentId, isGroup, prodecureName, ...rest } = appointment;

  return {
    ...rest,
    appointmentId: uniqueGroup ? `${appointmentId}-${uniqueId++}` : appointmentId,
    isGroup,
    procedures: [],
  };
};
/**
 * Groups appointments by id sharing common values and combining procedures.
 *
 * @param appointments - individual appoitnments with non-unique ids
 * @returns - appointments with unique ids containing a list of procedures
 */
export const parseAppointments = (appointments: Appointment[]): AppointmentGroup[] => {
  const cache: Record<string, AppointmentGroup> = {};

  return appointments.reduce((acc: AppointmentGroup[], appointment) => {
    const { appointmentId } = appointment;
    if (!cache[appointmentId]) {
      cache[appointmentId] = createNewGroup(appointment);
      acc.push(cache[appointmentId]);
    }

    const group = cache[appointment.appointmentId];
    if (group && group.startTime === appointment.startTime && group.modalityName === appointment.modalityName) {
      const { appointmentId, prodecureName } = appointment;
      cache[appointmentId].procedures.push(prodecureName);
    }

    return acc;
  }, []);
};
