import { ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';

import { IdsIcon } from '@abbadox-monorepo/shared-ui';
import { IconNames } from '@abbadox-monorepo/shared-ui/lib/components/icon';

export interface KioskDialogData {
  title: string;
  iconName?: IconNames;
}

@Component({
  selector: 'kiosk-dialog-shell',
  standalone: true,
  imports: [IdsIcon],
  template: `
    <div class="dialog-container">
      <div class="dialog-header dialog-title-outlined">
        <span class="header-title">
          <i idsIcon [icon]="icon()" color="var(--color-scheme-alert)"></i>
          <h2>{{ title() }}</h2>
        </span>
        <span class="header-close-action">
          <button (click)="closeDialog()">
            <i idsIcon icon="close" color="var(--color-scheme-text-secondary)"></i>
          </button>
        </span>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./dialog-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskDialogShell {
  readonly title = input('');
  readonly icon = input('exclamation-circle');

  readonly closeClick = output();

  closeDialog() {
    this.closeClick.emit();
  }
}
