/** Meta overrides for widget fields. */
export type StepWidget = {
  iconOverride: string | null;
  sortOrder: number;
  headingOverride: string;
  subHeadingOverride: string | null;
  accentColorOverride: string | null;
  failureMessageOverride: string | null;

  /** The main widget content */
  widget: Widget;
};

export type Widget = {
  /**
   * Widget icon
   * iconOverride: string | null;
   */
  icon: string | null;
  widgetId: number;

  /**
   * The hyphenated name to track entity
   */
  widgetName: string;

  /**
   * Widget heading
   * headingOverride: string;
   */
  heading: string;

  /**
   * Widget sub heading
   * subHeadingOverride: string | null;
   */
  subHeading: string | null;

  /**
   * Widget accent color
   * accentColorOverride: string | null;
   */
  accentColor: string;

  /**
   * Widget failure message
   * failureMessageOverride: string | null;
   */
  failureMessage: string;

  /** Controls the actions the widget should perform */
  widgetDependencies: WidgetDependency[];
};

export type WidgetDependency = {
  dependentWidgetId: number;
};

// Known widgets
export const enum WIDGET_NAMES {
  AUTHENTICATION = 'search',
  PATIENT_DETAILS = 'patient',
  APPOINTMENT_DETAILS = 'appointments',
  BILLING = 'billing',
  FORMS = 'forms',
  UPLOAD_INDENTIFICATION_FRONT = 'identification-front',
  UPLOAD_INDENTIFICATION_BACK = 'identification-back',
  UPLOAD_INSURANCE_FRONT = 'insurance-front',
  UPLOAD_INSURANCE_BACK = 'insurance-back',
  CONFIRMATION = 'confirmation',
}

export const defaultWidget = {
  isActive: false,
  iconOverride: null,
  sortOrder: 0,
  stepWidgetId: 0,
  headingOverride: '',
  subHeadingOverride: null,
  accentColorOverride: null,
  failureMessageOverride: null,
  widget: {
    heading: '',
    subHeading: null,
    icon: null,
    isActive: false,
    widgetId: 0,
    widgetName: '',
    accentColor: '',
    failureMessage: '',
  },
};
