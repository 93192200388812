import { StepWidget } from './widget.model';

export type Steps = {
  entities: Step[];
  total: number;
};

export type Step = {
  /**
   * The workflow id linked to actions and steps.
   * Manages the step entity and links step entities with workflows.
   */
  workflowId: number;

  /** The order of each step */
  sortOrder: number;

  /** The display name for the step */
  stepName: string;

  /** The page's widgets to perform actions. */
  stepWidgets: StepWidget[];

  /** The page's route. */
  route: string;
};

export const defaultStep: Step = {
  /**
   * The workflow id linked to actions and steps.
   * Manages the step entity and links step entities with workflows.
   */
  workflowId: 0,

  /** The order of each step */
  sortOrder: 0,

  /** The display name for the step */
  stepName: '',

  /** The page's widgets to perform actions. */
  stepWidgets: [],

  /** The page's route. */
  route: '',
};

export const enum STEP_ROUTES {
  AUTHENTICATION = 'authentication',
  PATIENT_INFORMATION = 'patient-information',
  APPOINTMENT_VERIFICATION = 'appointment-verification',
  SCAN_IDS = 'scan-ids',
  SCAN_INSURANCE = 'scan-insurance',
  PATIENT_FORMS = 'patient-forms',
  BILLING = 'billing',
  CONFIRMATION = 'confirmation',
}
