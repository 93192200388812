import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { Appointment, AppointmentsQueryDTO, EventDTO } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsHttpService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);

  /**
   * Finds patient records matching search params.
   *
   * There can be multiple records for a patient.
   *
   * @param query - query params to search for a patient's appointments
   * @returns - a list of patient appointments
   */
  findAppointments(query: AppointmentsQueryDTO): Observable<Appointment[]> {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(query)) {
      if (key && value) {
        params = params.append(key, value);
      }
    }

    return this.kioskApiHttpService.get<Appointment[]>('/appointments', params);
  }

  findAppointmentsAsPromise(query: AppointmentsQueryDTO): Promise<Appointment[]> {
    return lastValueFrom(this.findAppointments(query));
  }

  /**
   * Create a new event for appointments.
   *
   * @param body - data containing appointment ids to create new events for
   * @returns - any
   */
  createNewAppointmentEvent(body: EventDTO): Observable<string> {
    return this.kioskApiHttpService.post<string, EventDTO>('/events', body);
  }

  createNewAppointmentEventAsPromise(body: EventDTO): Promise<string> {
    return lastValueFrom(this.createNewAppointmentEvent(body));
  }
}
