import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PushPipe } from '@ngrx/component';
import { filter, of, switchMap, tap, timer } from 'rxjs';

import { STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import {
  IdsButton,
  IdsCardComponent,
  IdsCardContent,
  IdsCardHeader,
  IdsCardTitle,
  IdsProgressBar,
} from '@abbadox-monorepo/shared-ui';

import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export const enum WRONG_LOCATION_DIALOG_TRIGGERS {
  CLOSE_ICON = 'Icon',
  CLOSE_BUTTON = 'Button',
  TIMEOUT = 'Timeout',
}

export interface KioskWrongLocationDialogData extends KioskDialogData {
  maxTimeout: number;
  scheduledLocation: string;
  currentLocation: string;
  sessionTimeout: number;
}

@Component({
  selector: 'kiosk-wrong-location-dialog',
  standalone: true,
  imports: [
    PushPipe,
    KioskDialogShell,
    IdsCardComponent,
    IdsCardContent,
    IdsCardHeader,
    IdsCardTitle,
    IdsButton,
    IdsProgressBar,
  ],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClick()">
      <ids-card class="mb-4" tier-2 ids-card-outlined color="var(--color-scheme-alert)">
        <ids-card-content>
          <p class="message-line">The appointment shown below is scheduled to take place at a different location.</p>
          <div>
            <p class="message-line">
              Scheduled Location: <span class="font-bold">{{ scheduledLocation() }}</span>
            </p>
            <p class="message-line">
              Current Location: <span class="font-bold">{{ currentLocation() }}</span>
            </p>
          </div>
          <p class="message-line">Please speak with the front desk staff.</p>
        </ids-card-content>
      </ids-card>

      <ids-card class="mb-4" tier-2 ids-card-outlined color="var(--color-scheme-alert)">
        <ids-card-header>
          <ids-card-title>
            This session will expire in&nbsp;<span>{{ timerRemaining() }} seconds.</span>
          </ids-card-title>
        </ids-card-header>

        <ids-card-content>
          @if (timer$ | ngrxPush) {}
          <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
        </ids-card-content>
      </ids-card>

      <div class="dialog-actions-footer">
        <button ids-adc-button color="secondary" (click)="handleCloseClickFromButton()">Go to Start Page</button>
      </div>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./wrong-location-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskWrongLocationDialog {
  readonly dialogRef = inject(MatDialogRef);
  readonly data = inject<KioskWrongLocationDialogData>(MAT_DIALOG_DATA);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly scheduledLocation = model(this.data.scheduledLocation);
  readonly currentLocation = model(this.data.currentLocation);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);
  readonly sessionTimeout = model(this.data.sessionTimeout);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = of(this.sessionTimeout()).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClick() {
    this.dialogRef.close(WRONG_LOCATION_DIALOG_TRIGGERS.CLOSE_ICON);
  }

  handleCloseClickFromButton() {
    this.dialogRef.close(WRONG_LOCATION_DIALOG_TRIGGERS.CLOSE_BUTTON);
  }

  returnToHome() {
    this.dialogRef.close(WRONG_LOCATION_DIALOG_TRIGGERS.TIMEOUT);
  }
}
