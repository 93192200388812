import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { KioskConfigurationsStore } from '../workflow-configs.store';

export const workflowsGuard: CanActivateFn = () => {
  const { loaded, loadKioskConfigs } = inject(KioskConfigurationsStore);

  if (!loaded()) {
    loadKioskConfigs();
  }

  return true;
};
