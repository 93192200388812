import { computed } from '@angular/core';

import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';

export type WorkflowStatus = 'init' | 'started' | 'completed';
export type WorkflowStatusState = { workflowState: WorkflowStatus };

/**
 * The `withWorkflowStatus` feature and updaters can be used to add the `workflowState`
 * state slice, along with the `stopped`, `started`, `completed`, and `reset` computed signals
 * to a signal store.
 *
 * @example A `BooksStore` instance will contain the following properties and methods:
 * State signals from withRequestStatus feature:
 * - workflowState: Signal<WorkflowStatus>
 * Computed signals from withWorkflowStatus feature:
 * - init: Signal<boolean>
 * - started: Signal<boolean>
 * - completed: Signal<boolean>
 *
 * @returns - signal store for workflow status
 */
export function withWorkflowStatus() {
  return signalStoreFeature(
    withState<WorkflowStatusState>({ workflowState: 'init' }),
    withComputed(({ workflowState }) => ({
      init: computed(() => workflowState() === 'init'),
      started: computed(() => workflowState() === 'started'),
      completed: computed(() => workflowState() === 'completed'),
    })),
  );
}

// State updaters for modifying the workflow status
export function setWorkflowInit(): WorkflowStatusState {
  return { workflowState: 'init' };
}

export function setWorkflowStarted(): WorkflowStatusState {
  return { workflowState: 'started' };
}

export function setWorkflowCompleted(): WorkflowStatusState {
  return { workflowState: 'completed' };
}
