import { Component, input } from '@angular/core';

import { TitleCasePipe } from '@abbadox-monorepo/shared-ui';
import { RadialProgressStepperComponent } from '@abbadox-monorepo/shared-ui/lib/components/radial-progress-stepper/radial-progress-stepper.component';

@Component({
  selector: 'kiosk-header',
  standalone: true,
  imports: [RadialProgressStepperComponent, TitleCasePipe],
  template: `
    <header
      class="header-drop-shadow-light fixed z-10 flex h-[84px] w-full max-w-full items-center justify-between bg-white p-3 px-4"
    >
      <div class="w-[270px]">
        @if (logo()) {
          <img [src]="'data:image/png;base64, ' + logo()" class="h-[3.25rem]" alt="Logo Here" />
        }
      </div>

      @if (percentage()) {
        <ids-radial-progress-stepper [progress]="percentage()"></ids-radial-progress-stepper>
      }

      <div class="flex min-w-[270px] flex-col items-end">
        <span class="text-[32px] font-medium leading-[38px] text-[#141414]">{{ stepTitle() | titlecase }}</span>
        <span class="text-2xl leading-7 text-[#787885]">{{ stepName() }}</span>
      </div>
    </header>
  `,
})
export class KioskHeader {
  logo = input<string | null | undefined>('');
  stepTitle = input.required<string>();
  stepName = input.required<string>();
  percentage = input.required<number>();
}
