export const enum IMAGE_CAPTURE {
  FRONT = 'front',
  BACK = 'back',
}

export type UploadWizardState = {
  step: IMAGE_CAPTURE;
  started: boolean;
  idRecentlyUploaded: boolean;
  insuranceRecentlyUploaded: boolean;
};

export const initialUploadWizardState: UploadWizardState = {
  step: IMAGE_CAPTURE.FRONT,
  started: false,
  idRecentlyUploaded: false,
  insuranceRecentlyUploaded: false,
};

export type DocumentParams = Record<string, string>;

export type UploadMetadata = {
  accountName: string;
  patientID: string;
  appointmentIDs: string;
  workType: string;
};
