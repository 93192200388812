import { PatientInfo } from '@abbadox-monorepo/kiosk-core-api-interfaces';

import { defaultPatient, Patient } from './models/patient.model';

export const parsePatient = (patient: PatientInfo | null): Patient => {
  const details = patient ?? defaultPatient;

  return {
    autoCount: details.autoCount,
    mrn: details.mrn ?? '',
    patientFirst: details.patientFirst ?? '',
    patientMiddle: details.patientMiddle ?? '',
    patientLast: details.patientLast ?? '',
    sex: details.sex ?? '',
    ptDOB: details.ptDOB ?? '',
    phone: details.phone ?? '',
    mobilePhone: details.mobilePhone ?? '',
    email: details.email ?? '',
    address: details.address ?? '',
    address2: details.address2 ?? '',
    city: details.city ?? '',
    state: details.state ?? '',
    zipcode: details.zipcode ?? '',
  };
};
