import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { KioskConfiguration } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

/**
 * Loads relevant configurations for the app.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkflowsHttpClientService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);

  configs(): Observable<KioskConfiguration> {
    return this.kioskApiHttpService.get<KioskConfiguration>('/workflows');
  }

  configsAsPromise(): Promise<KioskConfiguration> {
    return lastValueFrom(this.configs());
  }
}
