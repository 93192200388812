import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import {
  PatientInfo,
  PatientRecordsResponse,
  PatientCommentsDTO,
  GenericBatchUpdateResponse,
  CommentedEntityType,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { KioskApiHttpService } from '@abbadox-monorepo/kiosk-core-http-client';

import { PatientSearchParams } from '../models/patient.model';

@Injectable({ providedIn: 'root' })
export class PatientsHttpService {
  private readonly kioskApiHttpService = inject(KioskApiHttpService);

  /**
   * Finds a single patient record by patient id.
   *
   * @param patientId - the patient id/mrn to search for
   * @returns - the found patient record
   */
  findPatientDetails(patientId: string): Observable<PatientInfo> {
    return this.kioskApiHttpService.get<PatientInfo>(`/patients/${patientId}`);
  }

  /**
   * Finds patient records matching search params.
   *
   * There can be multiple records for a patient.
   *
   * @param args - query params to search for a patient
   * @returns - a list of patients matching a search query
   */
  searchPatients(formState: PatientSearchParams): Observable<PatientRecordsResponse> {
    let params = new HttpParams();

    for (const key in formState) {
      if (formState[key]) {
        params = params.append(key, formState[key]);
      }
    }

    return this.kioskApiHttpService.get<PatientRecordsResponse>('/patients', params);
  }

  searchPatientsAsPromise(formState: PatientSearchParams): Promise<PatientRecordsResponse> {
    return lastValueFrom(this.searchPatients(formState));
  }

  /**
   * Updates the comments on a patient record.
   *
   * @param patientId - the id for the patient record to update
   * @param params - the data to update on the patient record
   * @returns - the modified patient record
   */
  updatePatientRecordsComments(patientId: string, params: PatientCommentsDTO): Observable<GenericBatchUpdateResponse> {
    return this.kioskApiHttpService.put<GenericBatchUpdateResponse, PatientCommentsDTO>(
      `/patients/${patientId}/comments`,
      { ...params, commentedEntityType: CommentedEntityType.Appointment },
    );
  }

  updatePatientRecordsCommentsAsPromise(
    patientId: string,
    params: PatientCommentsDTO,
  ): Promise<GenericBatchUpdateResponse> {
    return lastValueFrom(this.updatePatientRecordsComments(patientId, params));
  }
}
