import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

import { setError, withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';
import { AppointmentsStore } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { CommentedEntityType, CommentType, PatientCommentsDTO } from '@abbadox-monorepo/kiosk-core-api-interfaces';

import { initialPatientAppointmentComments, Patient } from './models/patient.model';
import { PatientsHttpService } from './services/patients-http-client.service';

export const PatientDetailsStore = signalStore(
  { providedIn: 'root' },
  withState(initialPatientAppointmentComments),
  withRequestStatus(),
  withLogger('patient details'),
  withMethods(
    (state, patientsHttpService = inject(PatientsHttpService), appointmentsStore = inject(AppointmentsStore)) => {
      function addComment(type: CommentType, comment: string): void {
        patchState(state, { comments: { ...state.comments(), [type]: comment } });
      }

      function resetPatientCommentsState(): void {
        patchState(state, initialPatientAppointmentComments);
      }

      async function saveComments(patient: Patient, type: CommentType): Promise<void> {
        const commentText = state.comments()[type];

        if (!commentText) {
          return;
        }

        const params: PatientCommentsDTO = {
          patientMRN: patient.mrn,
          appointmentIDs: appointmentsStore.appointmentIdsAsNumbers(),
          commentText,
          commentType: type,
          commentedEntityType: CommentedEntityType.Appointment,
        };

        try {
          await patientsHttpService.updatePatientRecordsCommentsAsPromise(String(patient.autoCount), params);
          resetPatientCommentsState();
        } catch (error) {
          if (error instanceof HttpErrorResponse) {
            patchState(state, setError(error.message));
          }
        }
      }

      async function savePatientComments(patient: Patient | null): Promise<void> {
        if (!patient) {
          return;
        }

        await saveComments(patient, 'patient');
      }

      async function saveAppointmentComments(patient: Patient | null): Promise<void> {
        if (!patient) {
          return;
        }

        await saveComments(patient, 'appointment');
      }

      return { addComment, resetPatientCommentsState, savePatientComments, saveAppointmentComments };
    },
  ),
);
