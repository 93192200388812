import { Steps } from './step.model';

export type Workflow = {
  /**
   * The workflow id linked to actions and steps.
   * Manages the workflow entity and links workflow entities with steps.
   */
  workflowId: number;

  /** Display order of workflow buttons. */
  sortOrder: number;

  /** The action to perform by a workflow. */
  action: {
    /** Hyphenated version of the action name for tracking the selected workflow. */
    route: string;

    /** Workflow name. */
    actionName: string;

    /** Workflow display text icon. */
    buttonName: string;

    /** Workflow icon to display with the button name. */
    icon: string;
  };

  /** Status to change appointments to at the end of a workflow. */
  appointmentStatusChangeUponCompletion: number | null;

  /** The statuses to filter appointments by during search. */
  allowableStatuses: AllowableStatus[];

  /** The page to display content. */
  steps: Steps;
};

export type AllowableStatus = {
  statusId: number;
};

export const emptyWorkflow: Workflow = {
  workflowId: 0,
  sortOrder: 0,
  action: {
    route: '',
    actionName: '',
    buttonName: '',
    icon: '',
  },
  steps: {
    entities: [],
    total: 0,
  },
  appointmentStatusChangeUponCompletion: 0,
  allowableStatuses: [],
};

// Known workflows
export const enum WORKFLOW_NAMES {
  CHECK_IN = 'check-in',
  WALK_IN = 'walk-in',
}
