import { Component, input, ViewEncapsulation } from '@angular/core';

import { IdsIcon } from '@abbadox-monorepo/shared-ui';

@Component({
  selector: 'kiosk-tier-one-card-footer-notification',
  standalone: true,
  imports: [IdsIcon],
  template: `
    <div class="card-footer-notification">
      <span>
        <i idsIcon icon="info-circle" color="var(--color-scheme-primary)"></i>
      </span>
      <p>
        {{ notification() }}
      </p>
    </div>
  `,
  styleUrls: ['./tier-one-card-footer-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KioskTierOneCardFooterNotification {
  readonly notification = input.required<string>();
}
