import { inject } from '@angular/core';

import { withState, signalStore, withMethods, patchState } from '@ngrx/signals';

import { withLogger } from '@abbadox-monorepo/core-data-access';

import { IDLE_STATE, initialIdleState } from './models/idle.models';
import { IdleService } from './services/idle.service';

export const IdleStore = signalStore(
  { providedIn: 'root' },
  withState(initialIdleState),
  withLogger('idle'),
  withMethods((state, idleService = inject(IdleService)) => {
    function setIdleTimeout(idleTimeoutSeconds?: number): void {
      patchState(state, { idleTimeoutSeconds });
    }

    function setIdleState(idleState?: IDLE_STATE): void {
      patchState(state, { idleState });
    }

    function startIdling(): void {
      idleService.setup(state.idleTimeoutSeconds(), setIdleState);
      idleService.startIdling();
    }

    function stopIdleWatch(): void {
      idleService.stopIdling();
    }

    return { setIdleTimeout, setIdleState, startIdling, stopIdleWatch };
  }),
);
