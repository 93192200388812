import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { setError, withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';

import { IMAGE_CAPTURE, initialUploadWizardState } from './models/upload.models';
import { UploadHttpService } from './services/upload-http-client.service';

export const UploadWizardStore = signalStore(
  { providedIn: 'root' },
  withState(initialUploadWizardState),
  withRequestStatus(),
  withLogger('upload wizard state'),
  withComputed(({ step }) => ({
    isFrontStep: computed(() => step() === IMAGE_CAPTURE.FRONT),
    isBackStep: computed(() => step() === IMAGE_CAPTURE.BACK),
  })),
  withMethods((state, uploadHttpService = inject(UploadHttpService), authStore = inject(AuthStore)) => {
    function startCapture(): void {
      patchState(state, { started: true });
    }

    function toBackStep(): void {
      patchState(state, { step: IMAGE_CAPTURE.BACK, started: false });
    }

    function resetUploadWizard(): void {
      patchState(state, { step: IMAGE_CAPTURE.FRONT, started: false });
    }

    function resetUploadWizardState(): void {
      patchState(state, initialUploadWizardState);
    }

    async function verifyIdUploaded(patientMrn: string): Promise<void> {
      const workType = authStore.idsWorktype();

      if (!patientMrn || !workType) {
        return;
      }

      try {
        const idRecentlyUploaded = await uploadHttpService.verifyPatientIdUploadedAsPromise({ patientMrn, workType });
        patchState(state, { idRecentlyUploaded });
      } catch (err) {
        const error = err as HttpErrorResponse;
        patchState(state, { idRecentlyUploaded: false }, setError(error.message));
      }
    }

    async function verifyInsuranceUploaded(patientMrn: string): Promise<void> {
      const workType = authStore.insuranceWorktype();

      if (!patientMrn || !workType) {
        return;
      }

      try {
        const insuranceRecentlyUploaded = await uploadHttpService.verifyPatientInsuranceUploadedAsPromise({
          patientMrn,
          workType,
        });
        patchState(state, { insuranceRecentlyUploaded });
      } catch (err) {
        const error = err as HttpErrorResponse;
        patchState(state, { insuranceRecentlyUploaded: false }, setError(error.message));
      }
    }

    async function verifyUploadedFeatures(patientMrn: string) {
      await Promise.all([verifyIdUploaded(patientMrn), verifyInsuranceUploaded(patientMrn)]);
    }

    return { startCapture, toBackStep, resetUploadWizard, resetUploadWizardState, verifyUploadedFeatures };
  }),
);
