import { DEFAULT_IDLE_TIMEOUT_IN_SECONDS } from '@abbadox-monorepo/core-constants';

export enum IDLE_STATE {
  NOT_STARTED = 'NOT_STARTED',
  NOT_IDLE = 'NOT_IDLE',
  IDLE = 'IDLE',
  TIMED_OUT = 'TIMED_OUT',
}

export interface IdleState {
  idleTimeoutSeconds: number;
  idleState: IDLE_STATE;
}

export const initialIdleState: IdleState = {
  idleTimeoutSeconds: DEFAULT_IDLE_TIMEOUT_IN_SECONDS,
  idleState: IDLE_STATE.NOT_STARTED,
};
