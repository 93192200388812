import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { setError, setLoaded, setLoading, withLogger, withRequestStatus } from '@abbadox-monorepo/core-data-access';
import { AppointmentsStore } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import { PatientsSearchStore } from '@abbadox-monorepo/kiosk-patient-data-access';

import { extractTokenFromLauncherUrl } from './eforms-url.parser';
import { initialEformsState } from './models/realtime-forms.models';
import { EformsHttpService } from './services/eforms-http-client.service';

export const RealtimeFormsStore = signalStore(
  { providedIn: 'root' },
  withState(initialEformsState),
  withRequestStatus(),
  withLogger('realtime forms'),
  withComputed(
    (
      state,
      authStore = inject(AuthStore),
      patientsSearchStore = inject(PatientsSearchStore),
      appointmentsStore = inject(AppointmentsStore),
    ) => {
      const realtimeFormsPayload = computed(() => ({
        appointmentIds: appointmentsStore.appointmentIdsAsNumbers(),
        patientMRN: patientsSearchStore.selectedEntity()?.mrn ?? '',
        accountName: authStore.accountName(),
      }));

      return { realtimeFormsPayload };
    },
  ),
  withMethods((state, eformsHttpService = inject(EformsHttpService)) => {
    function resetRealtimeFormsState(): void {
      patchState(state, initialEformsState);
    }

    async function loadRealtimeForms(): Promise<void> {
      patchState(state, setLoading());

      try {
        const { eformsUrl } = await eformsHttpService.openEformsApiAsPromise(state.realtimeFormsPayload());
        const eformsToken = extractTokenFromLauncherUrl(eformsUrl);

        patchState(state, { eformsUrl, eformsToken }, setLoaded);
      } catch (err) {
        const error = err as HttpErrorResponse;
        patchState(state, setError(error.message));
      }
    }

    return { resetRealtimeFormsState, loadRealtimeForms };
  }),
);
